<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card color="#385F73" dark>
          <v-card-title class="text-h5">
            Dashboard
          </v-card-title>
        </v-card>
      </v-col>

      <v-col cols="4">
        <LanguageCard />
      </v-col>

      <v-col><user-card /> </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";
import LanguageCard from "../components/Dashboard/LanguageCard";
import UserCard from "../components/Dashboard/UserCard";

export default {
  components: {
    LanguageCard,
    UserCard
  },
  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {}
};
</script>
