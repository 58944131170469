<template>
  <v-card class="greeting-card">
    <v-row class="ma-0 pa-0">
      <v-col cols="8">
        <v-card-title class="text-no-wrap pt-1 ps-2">
          Account settings
        </v-card-title>
        <v-card-subtitle class="text-no-wrap ps-2">
          <!-- You have won Trophy -->
        </v-card-subtitle>
        <v-card-text class="d-flex align-center mt-2 pb-2 ps-2">
          <form class="width-100" @submit.prevent="submit">
            <v-text-field
              v-model="firstName"
              :counter="30"
              label="First Name"
              required
              outlined
              :error="$v.firstName.$invalid && firstName !== ''"
            />

            <v-text-field
              v-model="lastName"
              :counter="30"
              label="Last Name"
              required
              outlined
              :error="$v.lastName.$invalid && lastName !== ''"
            />

            <v-text-field
              v-model="email"
              label="E-mail"
              required
              outlined
              :error="$v.email.$invalid && email !== ''"
            />

            <v-btn
              color="accent"
              class="mr-4"
              @click="save"
              :disabled="$v.$invalid"
            >
              submit
            </v-btn>
          </form>
        </v-card-text>
      </v-col>

      <v-col cols="4">
        <v-img
          contain
          height="180"
          width="159"
          :src="
            require(`@/assets/images/misc/triangle-${
              $vuetify.theme.dark ? 'dark' : 'light'
            }.png`)
          "
          class="greeting-card-bg"
        />
        <v-img
          contain
          height="108"
          max-width="83"
          class="greeting-card-trophy"
          src="@/assets/images/3d-characters/pose-m-1.png"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
const { required, minLength, email } = require("vuelidate/lib/validators");

export default {
  components: {},
  validations: {
    firstName: {
      required,
      minLength: minLength(3)
    },
    lastName: {
      required,
      minLength: minLength(3)
    },
    email: {
      required,
      email
    }
  },

  data() {
    return {
      firstName: "",
      lastName: "",
      email: ""
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/currentUser"
    })
  },

  methods: {
    ...mapActions({
      update: "auth/updateUser"
    }),

    async save() {
      try {
        await this.update({
          id: this.user.id,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email
        });

        this.$toast.success("Content successful updated");
      } catch (error) {
        this.$toast.error(error);
      }
    }
  },

  mounted() {
    const { firstName, lastName, email } = this.user;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
  }
};
</script>

<style lang="scss" scoped>
.width-100 {
  width: 100%;
}

.greeting-card {
  position: relative;
  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>
