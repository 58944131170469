<template>
  <v-card class="greeting-card">
    <v-row class="ma-0 pa-0">
      <v-col cols="8">
        <v-card-title class="text-no-wrap pt-1 ps-2">
          Select the default language.
        </v-card-title>
        <v-card-subtitle class="text-no-wrap ps-2">
          <!-- You have won Trophy -->
        </v-card-subtitle>
        <v-card-text class="d-flex align-center mt-2 pb-2 ps-2">
          <v-radio-group v-model="radioGroup">
            <v-radio
              v-for="n in languages"
              :key="n.id"
              :label="`${n.title}`"
              :value="n.id"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
      </v-col>

      <v-col cols="4">
        <v-img
          contain
          height="180"
          width="159"
          :src="
            require(`@/assets/images/misc/triangle-${
              $vuetify.theme.dark ? 'dark' : 'light'
            }.png`)
          "
          class="greeting-card-bg"
        ></v-img>
        <v-img
          contain
          height="108"
          max-width="83"
          class="greeting-card-trophy"
          :src="
            `https://flagicons.lipis.dev/flags/4x3/${
              getShortcut === 'en' ? 'gb' : getShortcut
            }.svg`
          "
        ></v-img>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapMutations, mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      changed: false,
      languages: [
        { id: 0, title: "English", lng: "en" },
        { id: 1, title: "Polish", lng: "pl" },
        { id: 2, title: "Russian", lng: "ru" }
      ],

      radioGroup: 0
    };
  },

  watch: {
    radioGroup(e) {
      const lng = this.languages[e];
      this.setDefaultLanguage(lng);
    },

    getLanguage(e) {
      this.changed = true;
      if (Object.keys(this.getLanguage).length) {
        const { id } = this.getLanguage;
        this.radioGroup = id;
      } else {
        const lng = this.languages[this.radioGroup];
        this.setDefaultLanguage(lng);
      }
    }
  },

  computed: {
    ...mapGetters({
      getLanguage: "auth/defaultLanguage"
    }),

    getShortcut() {
      return this.languages[this.radioGroup].lng;
    }
  },

  methods: {
    ...mapMutations({
      setDefaultLanguage: "auth/SET_DEFAULT_LANGUAGE"
    })
  },

  mounted() {
    if (!this.changed) {
      const { id } = this.getLanguage;
      this.radioGroup = id;
    } else {
      const lng = this.languages[this.radioGroup];
      this.setDefaultLanguage(lng);
    }
  }
};
</script>

<style lang="scss" scoped>
.greeting-card {
  position: relative;
  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>
